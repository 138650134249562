<template>
  <div>
   <v-app-bar color="primary" class="fixed-nav" flat>
       <v-icon @click="back()" dark class="ml-2">mdi-arrow-right</v-icon>
     <v-toolbar-title v-text="title" class="onPrimary--text"></v-toolbar-title>
     <v-spacer></v-spacer>
     <v-icon @click="shop()" v-if="shoping" class="onPrimary--text">mdi-storefront-outline</v-icon>
     <v-app-bar-nav-icon v-if="!shoping && login" class="onPrimary--text" @click="drawer = !drawer"></v-app-bar-nav-icon>
   </v-app-bar>
<div class="overlay" @click.self="drawer = !drawer" v-if="drawer">
  <div class="drawer-container surface">
    <div class="pa-2 d-flex align-center">
      <v-icon class="primary--text" @click="drawer = !drawer">mdi-arrow-left</v-icon>
    </div>
    <v-divider class="dividers"></v-divider>
    <v-list dense class="surface">

        <!-- <v-list-item link>
          <v-list-item-content @click="goTo('/')" class="blocksFont--text py-0">
            <v-list-item-title><v-icon class="ml-2">mdi-shopping-outline</v-icon>فروشگاه</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item link>
          <v-list-item-content class="py-0">
            <v-list-item-title @click="changePassword()" class="medium-font">
              <v-icon class="ml-2">mdi-lock-outline</v-icon>تغییر رمزعبور</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content class="py-0">
            <v-list-item-title @click="Out('logOut')" class="medium-font">
              <v-icon class="ml-2">mdi-logout</v-icon> خروج از حساب</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
  </div>
</div>
 </div>
</template>
<style lang="scss" scoped>
.fixed-nav{
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 300;
}
</style>
<script>
import router from '@/router'
import { logOut } from '@/views/auth/models/auth'
export default {
  props: ['title', 'route'],
  data: () => ({
     drawer: false,
     shoping: false,
     login: true,

  }),
  mounted () {
    const route = this.$router.history.current.name
    if ( route == 'reciever' || route == 'addReciever' || route == 'send_time' || route == 'payment') {
      this.shoping = true
    }
    const token = localStorage.getItem('access_token')
    if (!token || route != 'profile') {
      this.login = false
    }
  },
  methods: {
    back () {
      if (this.$props.route) {
        router.push(this.$props.route)
      } else {
        router.back()
      }
    },
    goTo (route) {
      router.push(route)
    },
     Out(type) {
      if (type == 'logOut') {
        logOut()
      }
      },
      changePassword () {
        router.push('/change_password')
      },
      shop () {
        router.push('/')
      }
  }
}
</script>
